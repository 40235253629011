<template>
  <div class="page">
    <b-card>
      <b-button
        variant="outline-primary"
        class="mb-2"
        @click="$router.push({name: 'create-predefine'})"
      >
        <feather-icon icon="PlusIcon" />
        Add New Predefine
      </b-button>
      <b-table
        v-if="rows"
        responsive="sm"
        :fields="fields"
        :items="predefines"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(name)="data">
          <router-link :to="'/predefines/' + data.item.id">
            {{ data.value }}
          </router-link>
        </template>

        <template #cell(shortcode)="data">
          <span class="d-flex">{{ data.value }}</span>
          <span
            v-b-modal.view-shortcode
            style="text-decoration: underline;"
            @click="onViewShortCodeClick(data.value)"
          >View</span>
        </template>

        <template #cell(created_at)="data">
          {{ formatDate(data.value) }}
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item :to="'/predefines/' + data.item.id">
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.delete-modal
              @click="onRemoveClick(data.item)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>Remove</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-pagination
        v-if="rows"
        v-model="currentPage"
        class="d-flex justify-content-center"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </b-card>

    <b-card title="Search Form">

      <b-form-group
        v-slot="{ ariaDescribedby }"
        class="classRadioContainer"
        label="Class"
      >
        <b-form-radio-group
          id="radio-group-form-type"
          v-model="classSelected"
          :options="classOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-form-options"
          @change="onFormTypeChange"
        />
      </b-form-group>

      <b-form-group
        v-slot="{ ariaDescribedby }"
        class="classRadioContainer"
        label="Color Theme"
      >
        <b-form-radio-group
          id="theme-radio-group-form-type"
          v-model="themeSelected"
          :options="themeOptions"
          :aria-describedby="ariaDescribedby"
          name="theme-radio-form-options"
          @change="onFormTypeChange"
        />
      </b-form-group>

      <b-form-group>
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            id="search-from"
            ref="searchFormCodeContainer"
            v-model="searchFormCode"
            class="form-control-merge"
            name="price-from"
            readonly
          />

          <b-input-group-append>
            <b-button
              type="submit"
              variant="outline-primary"
              @click="copySearchFormCode"
            >
              Copy
            </b-button>
          </b-input-group-append>
        </b-input-group>

      </b-form-group>

    </b-card>

    <b-modal
      id="view-shortcode"
      ref="view-shortcode-modal"
      title="Shortcode"
      centered
      hide-footer
    >
      <b-form-group
        label="Type"
        label-for="type"
      >
        <v-select
          v-model="shortcodePropertyType"
          :options="['Predefined', 'Showcase']"
          :searchable="false"
          :clearable="false"
        />
      </b-form-group>

      <b-form-group
        label="Sort by"
        label-for="sort-opt"
      >
        <v-select
          v-model="shortcodeSortOpt"
          label="label"
          :options="sortOpt"
          :reduce="opt => opt.value"
          :searchable="false"
          :clearable="false"
        />
      </b-form-group>

      <b-form-group
        label="Shortcode"
        label-for="shortcode"
      >
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            id="price-from"
            ref="shortcodeContainer"
            v-model="getShortcode"
            class="form-control-merge"
            name="price-from"
            readonly
          />
        </b-input-group>

      </b-form-group>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <b-button
            class="mr-1"
            variant="outline-danger"
            @click="hideModal"
          >
            Close
          </b-button>
          <b-button
            variant="outline-primary"
            @click="copyShortCode"
          >
            Copy Shortcode
          </b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard, BTable, BButton, BDropdownItem, BDropdown, BFormInput, BInputGroup, BFormGroup, BPagination, BInputGroupAppend, BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard, BTable, BButton, BDropdownItem, BDropdown, BFormInput, BInputGroup, BFormGroup, vSelect, BPagination, BInputGroupAppend, BFormRadioGroup,
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      fields: ['id', 'name', { key: 'predefine_type', sortable: true }, 'shortcode', { key: 'created_at', sortable: true }, 'action'],
      predefines: [],
      selectedShortCode: '',
      shortcodePropertyType: 'Predefined',
      shortcodeSortOpt: 'DateDesc',
      sortOpt: [
        {
          value: 'DateDesc',
          label: 'Date (New - Old)',
        },
        {
          value: 'DateAsc',
          label: 'Date (Old - New)',
        },
        {
          value: 'PriceDesc',
          label: 'Price (High - Low)',
        },
        {
          value: 'PriceAsc',
          label: 'Price (Low - High)',
        },
      ],
      searchFormCode: '[onikon r="sf" class="residential" c="dark"]',
      classSelected: 'residential',
      classOptions: ['residential', 'commercial', 'rental'],
      themeOptions: ['dark', 'light'],
      themeSelected: 'dark',
    }
  },
  computed: {
    rows() {
      return this.predefines.length
    },
    getShortcode() {
      let propType = ''

      switch (this.shortcodePropertyType) {
        case ('Predefined'):
          propType = 'p'
          break
        case ('Showcase'):
          propType = 'sc'
          break
        default: propType = 'p'
      }

      const ele = this.predefines.find(obj => {
        return obj.shortcode === this.selectedShortCode
      })

      let shortcode = '[onikon r="' + propType + '" k="' + this.selectedShortCode + '" sb="' + this.shortcodeSortOpt + '"'

      if (ele && ele.predefine_type === 'Featured') {
        shortcode += ' so="1" '
      }

      if (propType === 'sc') {
        shortcode += '  p="featured-listings" '
      }

      shortcode += ']'
      return shortcode
    },
  },
  created() {
    this.$store.dispatch('predefines/getAllPredefine').then(response => {
      if (response.code === 200) {
        this.predefines = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    onFormTypeChange() {
      this.searchFormCode = '[onikon r="sf" class="' + this.classSelected + ' c="' + this.themeSelected + '"]'
    },
    formatDate(date) {
      return new Date(date).toLocaleString('en-CA')
    },
    onRemoveClick(item) {
      this.$store.dispatch('predefines/deletePredefine', item.id).then(response => {
        if (response.code === 200) {
          this.showToast('Predefine Deleted', 'CheckCircleIcon', 'Predefine has been deleted successfully', 'success', 'bottom-right')
          this.predefines = this.predefines.filter(data => data.id !== null && data.id !== response.data.id)
        } else {
          this.showToast('Failed', 'CheckCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    onViewShortCodeClick(shortcode) {
      this.selectedShortCode = shortcode
    },
    hideModal() {
      this.$refs['view-shortcode-modal'].hide()
      this.selectedShortCode = ''
      this.shortcodePropertyType = 'Predefined'
      this.shortcodeSortOpt = 'DateDesc'
    },
    copyShortCode() {
      this.$refs.shortcodeContainer.select()
      document.execCommand('copy')
      this.showToast('Copied', 'CheckCircleIcon', 'Shortcode has been copied to clipboard', 'success', 'bottom-right')
    },
    copySearchFormCode() {
      this.$refs.searchFormCodeContainer.select()
      document.execCommand('copy')
      this.showToast('Copied', 'CheckCircleIcon', 'Shortcode has been copied to clipboard', 'success', 'bottom-right')
    },
  },
}
</script>

<style lang="scss">

.custom-control-label span{
  text-transform: capitalize;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
